import http from '../clients/Axios'
export default class LaporanPersediaanBarangService {
  async getLaporanKartu(start_date, end_date, obat_id) {
    obat_id = obat_id === undefined ? 0 : obat_id
    const res = await http.get(
      `/report/stock/kartu?filter[periode_from]=${start_date}&filter[periode_to]=${end_date}&filter[obat_id]=${obat_id}`
    )
    return res
  }

  async printLaporanKartu(start_date, end_date, obat_id, batch_id, harga) {
    const params = new URLSearchParams()
    harga = harga === undefined ? 0 : harga
    if (start_date) {
      params.append('filter[periode_from]', start_date)
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    if (obat_id) {
      params.append('filter[obat_id]', obat_id)
      if (batch_id) {
        params.append('filter[batch_id]', batch_id)
      }
    }
    if (harga) {
      params.append('filter[harga]', harga)
    } else {
      params.append('filter[harga]', '0')
    }

    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/stock/kartu/print?t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async exportLaporanKartu(start_date, end_date, obat_id, batch_id, harga) {
    const params = new URLSearchParams()
    harga = harga === undefined ? 0 : harga
    if (start_date) {
      params.append('filter[periode_from]', start_date)
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    if (obat_id) {
      params.append('filter[obat_id]', obat_id)
      if (batch_id) {
        params.append('filter[batch_id]', batch_id)
      }
    }
    if (harga) {
      params.append('filter[harga]', harga)
    } else {
      params.append('filter[harga]', '0')
    }

    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/stock/kartu/export?t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async getLaporanKartuPaginate(
    pageNumber,
    pageSize = 10,
    start_date,
    end_date,
    obat_id
  ) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    obat_id = obat_id === undefined ? 0 : obat_id
    const res = await http.get(
      `/report/stock/kartu/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&filter[periode_from]=${start_date}&filter[periode_to]=${end_date}&filter[obat_id]=${obat_id}`
    )
    return res
  }

  async getLaporanMutasi(start_date, end_date, obat_id, pabrik_id) {
    obat_id = obat_id === undefined ? 0 : obat_id
    pabrik_id = pabrik_id === undefined ? 0 : pabrik_id
    const res = await http.get(
      `/report/stock/mutasi?filter[periode_from]=${start_date}&filter[periode_to]=${end_date}&filter[obat_id]=${obat_id}&filter[pabrik_id]=${pabrik_id}`
    )
    return res
  }

  async exportLaporanMutasi(
    start_date,
    end_date,
    obat_id,
    pabrik_id,
    golongan_id,
    harga
  ) {
    const params = new URLSearchParams()
    if (start_date) {
      params.append('filter[periode_from]', start_date)
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    if (pabrik_id) {
      params.append('filter[pabrik_id]', pabrik_id)
    }
    if (obat_id) {
      params.append('filter[obat_id]', obat_id)
    }
    if (golongan_id) {
      params.append('filter[golongan_id]', golongan_id)
    }
    if (harga) {
      params.append('filter[harga]', harga)
    } else {
      params.append('filter[harga]', '0')
    }
    return http({
      url: '/report/stock/mutasi/print',
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async getLaporanMutasiPaginate(
    pageNumber,
    pageSize = 10,
    start_date,
    end_date,
    obat_id,
    pabrik_id
  ) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    obat_id = obat_id === undefined ? 0 : obat_id
    pabrik_id = pabrik_id === undefined ? 0 : pabrik_id
    const res = await http.get(
      `/report/stock/mutasi/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&filter[periode_from]=${start_date}&filter[periode_to]=${end_date}&filter[obat_id]=${obat_id}&filter[pabrik_id]=${pabrik_id}`
    )
    return res.data
  }

  async getLaporanOpname(start_date, end_date, obat_id, pabrik_id) {
    obat_id = obat_id === undefined ? 0 : obat_id
    pabrik_id = pabrik_id === undefined ? 0 : pabrik_id
    const res = await http.get(
      `/report/stock/opname?filter[periode_from]=${start_date}&filter[periode_to]=${end_date}&filter[obat_id]=${obat_id}&filter[pabrik_id]=${pabrik_id}`
    )
    return res
  }

  async exportLaporanOpname(start_date, end_date) {
    return http({
      url: `/report/stock/opname/print?filter[periode_from]=${start_date}&filter[periode_to]=${end_date}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async getLaporanOpnamePaginate(
    pageNumber,
    pageSize = 10,
    start_date,
    end_date,
    obat_id,
    pabrik_id
  ) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    obat_id = obat_id === undefined ? 0 : obat_id
    pabrik_id = pabrik_id === undefined ? 0 : pabrik_id
    const res = await http.get(
      `/report/stock/opname/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&filter[periode_from]=${start_date}&filter[periode_to]=${end_date}&filter[obat_id]=${obat_id}&filter[pabrik_id]=${pabrik_id}`
    )
    return res
  }

  async getLaporanSlow(start_date, end_date, pabrik_id) {
    pabrik_id = pabrik_id === undefined ? 0 : pabrik_id
    const res = await http.get(
      `/report/stock/slow?filter[periode_from]=${start_date}&filter[periode_to]=${end_date}&filter[pabrik_id]=${pabrik_id}`
    )
    return res
  }

  async exportLaporanSlow(start_date, end_date, pabrik_id) {
    pabrik_id = pabrik_id === undefined ? 0 : pabrik_id
    return http({
      url: `/report/stock/slow/print?filter[periode_from]=${start_date}&filter[periode_to]=${end_date}&filter[pabrik_id]=${pabrik_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async getLaporanSlowPaginate(
    pageNumber,
    pageSize = 10,
    start_date,
    end_date,
    pabrik_id
  ) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    pabrik_id = pabrik_id === undefined ? 0 : pabrik_id
    const res = await http.get(
      `/report/stock/slow/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&filter[periode_from]=${start_date}&filter[periode_to]=${end_date}&filter[pabrik_id]=${pabrik_id}`
    )
    return res
  }

  async getLaporanBest(start_date, end_date, pabrik_id) {
    pabrik_id = pabrik_id === undefined ? 0 : pabrik_id
    const res = await http.get(
      `/report/stock/best?filter[periode_from]=${start_date}&filter[periode_to]=${end_date}&filter[pabrik_id]=${pabrik_id}`
    )
    return res
  }

  async exportLaporanBest(start_date, end_date, pabrik_id) {
    pabrik_id = pabrik_id === undefined ? 0 : pabrik_id
    return http({
      url: `/report/stock/best/print?filter[periode_from]=${start_date}&filter[periode_to]=${end_date}&filter[pabrik_id]=${pabrik_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async getLaporanBestPaginate(
    pageNumber,
    pageSize = 10,
    start_date,
    end_date,
    pabrik_id
  ) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    pabrik_id = pabrik_id === undefined ? 0 : pabrik_id
    const res = await http.get(
      `/report/stock/best/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&filter[periode_from]=${start_date}&filter[periode_to]=${end_date}&filter[pabrik_id]=${pabrik_id}`
    )
    return res
  }

  async getLaporanExpired(end_date, obat_id, pabrik_id) {
    obat_id = obat_id === undefined ? 0 : obat_id
    pabrik_id = pabrik_id === undefined ? 0 : pabrik_id
    const res = await http.get(
      `/report/stock/expired?filter[exp_date]=${end_date}&filter[obat_id]=${obat_id}&filter[pabrik_id]=${pabrik_id}`
    )
    return res
  }

  async exportLaporanExpired(end_date) {
    return http({
      url: `/report/stock/expired/print?filter[exp_date]=${end_date}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async getLaporanExpiredPaginate(
    pageNumber,
    pageSize = 10,
    end_date,
    obat_id,
    pabrik_id
  ) {
    obat_id = obat_id === undefined ? 0 : obat_id
    pabrik_id = pabrik_id === undefined ? 0 : pabrik_id
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const res = await http.get(
      `/report/stock/expired/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&filter[exp_date]=${end_date}&filter[obat_id]=${obat_id}&filter[pabrik_id]=${pabrik_id}`
    )
    return res
  }

  async getLaporanGolongan(golongan_id) {
    const res = await http.get(
      `/report/stock/golongan?filter[golongan_id]=${golongan_id}`
    )
    return res
  }

  async exportLaporanGolongan(golongan_id) {
    return http({
      url: `/report/stock/golongan/print?filter[golongan_id]=${golongan_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async getLaporanGolonganPaginate(pageNumber, pageSize = 10, golongan_id) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const res = await http.get(
      `/report/stock/golongan/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&filter[golongan_id]=${golongan_id}`
    )
    return res
  }

  async exportLaporanGlobal(start_date, end_date, obat_id) {
    const params = new URLSearchParams()
    params.append('filter[obat_id]', obat_id)
    if (start_date != '') {
      params.append('filter[periode_from]', start_date)
    }
    if (end_date != '') {
      params.append('filter[periode_to]', end_date)
    }
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/stock/global/print?t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async exportLaporanGlobalExcel(start_date, end_date, obat_id) {
    const params = new URLSearchParams()
    params.append('filter[obat_id]', obat_id)
    if (start_date != '') {
      params.append('filter[periode_from]', start_date)
    }
    if (end_date != '') {
      params.append('filter[periode_to]', end_date)
    }
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/stock/global/export?t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }
}
